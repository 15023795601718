<template>
	<v-sheet>
		<mw-list-defined 
			storeModule="appointments"
			:items="appointmentIds"
			:columns="columns"
			:show-edit="false"
		>
			<template v-slot:item.start="item">
				<span v-if="item.start && item.start.toDate">
					{{ item.start.toDate() | moment("HH:mm, MMMM Do YYYY") }}
				</span>
			</template>
			<template v-slot:item.project="item">
				<span v-if="projects[item.project]">
					{{ projects[item.project].internal_name }}
				</span>
			</template>
			<template v-slot:item.view="item">
				<v-btn router-link :to="`/project-appointments/${item.project}`" color="primary" text>Edit Appointments</v-btn>
			</template>
		</mw-list-defined>
		<secondary show-back></secondary>
	</v-sheet>
</template>

<script type="text/javascript">
import MwListDefined from "@c/ui/MwListDefined";
import Secondary from '@c/navigation/Secondary.vue';

export default {
	name: "UserAppointments",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	data: () => ({
		project_ids: [],
		appointment_ids: [],
		direction: 1,
		focus: null,
		columns: [
			{ value: "start", text: "Appointment Date", default: true },
			{ value: "project", text: "Project", default: true },
			{ value: "view", text: "", default: true },
		],
	}),
	computed: {
		appointmentParticipants() {
			return this.$store.getters.getByKeyContains( "appointmentParticipants", "user", this.id )
		},
		appointmentIds(){
			const self = this;
			return self.appointmentParticipants.map( ap => ap.appointment )
		},
		appointments(){
			const self = this;
			return self.appointment_ids.map( a => self.$store.state.appointments.data[a]).filter( b => b);
		},
		projectIds() {
			let ids = this.appointments.map((a) => a.project);
			return this.mwutils.uniqueArray(ids);
		},
		projects() {
			return this.$store.state.projects.data;
		},
	},
	components: {
		Secondary,
		MwListDefined
	},
	watch: {
		appointmentParticipants: {
			immediate: true, 
			handler(v){
				this.appointment_ids = v.map( ap => ap.appointment )
			} 
		},		
		appointment_ids: {
			immediate: true,
			handler(value, old) {
				if (value && value !== old && value.length ) {
					this.$store.dispatch("appointments/fetchByIds", value);
				}
			}
		}, 
		appointments: {
			immediate: true,
			handler(v){
				let ids = v.map((a) => a.project);
				this.project_ids = this.mwutils.uniqueArray(ids);
			}
		},
		project_ids: {
			immediate: true,
			handler(value, old) {
				if (value && value !== old && value.length ) {
					this.$store.dispatch("projects/fetchByIds", value);
				}
			},
		},
	},
	created() {
		this.$store.dispatch("appointmentParticipants/fetchByKey", {key: 'user', value: this.id });
	},
};
</script>
