<template>
	<v-sheet color="transparent" class="pa-4">
		<v-text-field
			v-model="search"
			label="Search"
		></v-text-field>
		<v-card flat color="secondary">
			<v-data-table
				:headers="headers"
				:search="search"
				:items="documents"
				class="mw-database-list"
				@click:row="select"
			>
				<!-- if the field has a named slot -->
				<template v-for="slot in customSlots" v-slot:[slot]="{ item }">
					<span :key="slot"> <slot :name="slot" v-bind="item"></slot></span>
				</template>
				<!--  -->
				<template v-slot:item.button="{ item }">
					<span :id="item.id"></span>
                    <list-buttons
						:id="item.id"
						:deleteBtn="false"
						:copyBtn="false"
						@edit="select(item)"
					></list-buttons>
				</template>
				<template v-slot:footer.prepend>
					<export :columns="headers" :rows="documents"></export>
				</template>
			</v-data-table>
		</v-card>
		<p class="text-right" v-if="newBtn">
			<v-btn color="primary" :to="`/${editPath}/new`">{{
				$t(`buttons.new`)
			}}</v-btn>
		</p>
	</v-sheet>
</template>

<style lang="less">
.mw-database-list{ 
	tr {
		cursor: pointer;
	}
	td:last-child {
		padding-right: 0px !important;
		text-align: right !important;
	}
}
</style>
<script type="text/javascript">
// A component to display a defined list instead of the whole store module
import ListButtons from "@c/buttons/ListButtons";
import Export from '@c/downloads/Export.vue';

export default {
	name: "MwListDefined",
	props: {
		storeModule: { type: String }, // @doc - the corresponding data module in vuex
		columns: { type: Array }, // @doc the headers for the table - an array of {key: String, value: String, default: Boolean}. Default true for fields which are included in the import
		items: { type: Array, default: () => ([])  }, //@doc the ids		
		path: { type: String }, // @doc the path of the linked object - almost always the same as storeModule
		showExport: {type: Boolean, default: true },
		newBtn: {type: Boolean, default: true },
		showEdit: {type: Boolean, default: true },

	},
	data: () => {
		return {
			search: "",
			filtered: false,
		};
	},
	components: {
		ListButtons, 
		Export
	},
	computed: {
		editPath(){
			return this.path || this.storeModule
		},
		defaultFields() {
			return this.headers
				.filter((col) => col.default)
				.map((col) => col.value)
				.join(",");
		},
		documents() {
			const self = this;
			return self.items.map( i => self.$store.state[self.storeModule].data[i])
		},
		headers() {
			var headers = this.columns;
			if( this.showEdit ){
				headers.push({ value: "button", text: " ", width: "35%" });
			}
			return headers;
		},		customSlots() {
			return Object.keys(this.$scopedSlots);
		},
	},
	methods: {
		select(item){
			this.$emit('edit', item.id)
		}
	},
	created() {
		this.$store.dispatch(`${this.storeModule}/fetchByIds`, this.items );
	}
};
//
</script>
"
